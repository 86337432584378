import { Link, useNavigate } from "react-router-dom";
import BG2 from "../../assets/images/inner-banner/bg2.jpg";
import Acoount from "../../assets/images/account/bg.jpg";
import Logo from "../../assets/images/load.gif";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";

const Login = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    nickiname: "",
    password: "",
  });

  const setData = (key, value) => {
    const copyOfUser = { ...user };
    copyOfUser[key] = value;
    setUser(copyOfUser);
  };

  const loginEvent = () => {
    if (!user.nickiname || !user.password) {
      toast.error("Please fill all the fields");
      return false;
    }

    axios
      .post("https://api-by.devcom.ge/api/user/auth", {
        username: user.nickiname,
        password: user.password,
      })
      .then((res) => {
        if (res.data) {
          navigate(`/game?sessionKey=${res.data}`);
        }
        toast.success("Successfully logged in");
      })
      .catch(() => {
        toast.error("Incorect credentials");
      });
  };

  return (
    <section>
      <Toaster />
      <section
        className="inner-banner bg_img"
        style={{ background: `url(${BG2}) top` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-6 text-center">
              <h2 className="title text-white">Sign In</h2>
            </div>
          </div>
        </div>
      </section>

      <section
        className="account-section overflow-hidden bg_img"
        style={{ background: `background: url${Acoount}` }}
      >
        <div className="container">
          <div className="account__main__wrapper">
            <div className="account__form__wrapper">
              <div className="logo">
                <Link to={"/"}>
                  <img src={Logo} alt="Droid" />
                </Link>
              </div>
              <form className="account__form form row g-4">
                <div className="col-12">
                  <div className="form-group">
                    <div htmlFor="username" className="input-pre-icon">
                      <i className="las la-user"></i>
                    </div>
                    <input
                      id="username"
                      type="text"
                      className="form--control form-control style--two"
                      placeholder="Username"
                      value={user.nickiname}
                      onChange={(e) => setData("nickiname", e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div htmlFor="pass" className="input-pre-icon">
                      <i className="las la-lock"></i>
                    </div>
                    <input
                      id="pass"
                      type="password"
                      className="form--control form-control style--two"
                      placeholder="Password"
                      onChange={(e) => setData("password", e.target.value)}
                      value={user.password}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      className="cmn--btn active btn--lg button play-now w-100"
                      type="button"
                      onClick={() => loginEvent()}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between mt-5">
                  <div className="form--check d-flex align-items-center">
                    <input id="check1" type="checkbox" checked />
                    <label htmlFor="check1">Remember me</label>
                  </div>
                  <Link to="/forgot" className="forgot-pass d-block text--base">
                    Forgot Password ?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default Login;
